.o-ground {
  & &__info {
    position: absolute;
    top: 35%;
    left: 9%;
    width: 66%;
    transform: translateY(-100%);
  }

  &[data-status="true"] &__info {
    transform: translateY(0);
    transition: transform var(--second);
  }
}
