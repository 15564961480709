:root {
  --blue: #0755fd;
  --pink: #f0babf;
  --green: #97f1dd;
  --gray: #f2f2f2;
  --gray_d: #d9d9d9;
  --black: #131417;

  --zIndex__top: 9999;
  --zIndex__middle: 999;
  --zIndex__bottom: 99;

  --second: .8s;

  --planeWH: 50px;
}

@define-mixin topRight {
  top: 8%;
  right: 3.5%;
  @media (--phone) {
    top: 3%;
  }
}

@svg-load plane url(../images/paper-plane.svg) {
  fill: white;
}

@define-mixin backgroundMove {
  display: inline-block;
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--blue);
    transform: translateX(-100%);
    transition: transform calc( var(--second) * 1.5);
  }
}

@define-mixin backgroundWord {
  opacity: 0;
  transition: opacity .1s;
}

@define-mixin arrow {
  --wh: 1.4em;
  position: relative;
  padding-left: calc(var(--wh) + .8em);
  padding: calc(var(--wh) / 4) 0 calc(var(--wh) / 4) calc(var(--wh) + .8em);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: var(--wh);
    height: var(--wh);
    background: url(../images/icon_arrow@3x.png) 50%/contain no-repeat;
  }
}
