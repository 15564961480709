.c-social {
  & &__item {
    display: inline-block;
    overflow: hidden;
    margin-right: 1em;
    font-size: 1.1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
