.c-menu {
  & &__item {
    display: block;
    width: 100%;
    height: 7.5%;
    background-color: var(--black);
    transform-origin: right;
    transition: transform .3s,
    width .3s,
    background-color .4s;

    + .c-menu__item {
      width: 60%;
      margin: 27.5% 0 0 auto;
    }
  }

  &[data-status="false"]:hover {
    @media (--desktop) {
      .c-menu__item {
        &:first-child {
          width: 60%;
        }

        + .c-menu__item {
          width: 100%;
        }
      }
    }
  }

  &[data-status="true"] {
    .c-menu__item {
      &:first-child {
        transform: rotate(-45deg);
      }

      &:last-child {
        width: 100%;
        transform: rotate(45deg);
      }

      &:nth-child(2n) {
        visibility: hidden;
      }
    }
  }
}
