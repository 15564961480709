.o-ground {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background: url(../images/bg_ground_m.png) 50% 80%/100% auto no-repeat;
  transition: calc( var(--second) * 1.3);
  @media (--desktop) {
    background: url(../images/bg_ground_p.png) 50% 100%/100% auto no-repeat;
  }
  @media (--phone) {
    background-size: 200% auto;
    background-position: 2% 80%;
  }

  &.is-hidden {
    opacity: 0;
    transform: scale(.9);
    visibility: hidden;
  }
}
