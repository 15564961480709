.o-who {
  & &__pagination {
    bottom: 2em;

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background-color: var(--gray_d);
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background-color: white;
      }
    }
  }
}
