.c-social {
  &&_themes_square &__item {
    width: 1.7em;
    height: 1.7em;
    line-height: 1.7em;
    border: 2px solid var(--black);
    border-radius: .3em;
    font-size: .8rem;
  }
}
