.u-hidden {
  &\@lg {
    @media (--desktop) {
      display: none;
    }
  }

  &\@md {
    @media (--ipad) {
      display: none;
    }
    @media (--ipad_mini) {
      display: none;
    }
  }

  &\@xs{
    @media (--phone) {
      display: none;
    }
  }
}
