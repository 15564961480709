.u-color {
  &--white {
    color: white;
  }

  &--gray {
    color: color( var(--gray) b(+20%) );
  }

  &--pink {
    color: var( --pink );
  }

  &--blue {
    color: var( --blue );
  }

  &--black {
    color: var( --black );
  }
}
