.c-navigation {
  & &__tag {
    overflow: hidden;
    position: absolute;
    top: 50%;
    right: .5em;
    transform: translate(calc(100% + .5em), -100%);
    transition: transform calc( var(--second) - .3s);
    white-space: nowrap;
    font-size: 14px;
  }

  & &__font:hover .c-navigation__tag {
    transform: translate(0, -100%);
  }
}
