.c-form {
  & &__btn {
    margin: .4em 0 0 auto;
    padding-right: 0;
    background-color: transparent;
    border: none;
    font: .8rem Rubik, sans-serif;
    color: white;
    cursor: pointer;

    &::after {
      content: '';
      display: inline-block;
      width: 0;
      height: 1px;
      background-color: white;
      vertical-align: middle;
      transition: width .4s, 
      margin .4s;
    }

    &:hover {
      &::after {
        width: 2em;
        margin: 0 0 0 1em;
      }
    }
  }
}
