.c-menu-nav {
  & &__languages {
    position: absolute;
    top: 8%;
    right: 5.9em;
    @media (--phone) {
      top: 4%;
      right: 4.3em;
    }
  }
}
