.c-pop-form {
  position: fixed;
  left: 0;
  top: 0;
  z-index: var(--zIndex__middle);
  width: 100%;
  height: 100%;

  &[data-status="false"] {
    visibility: hidden;
    transition: visibility calc( var(--second) * 1 ) calc( var(--second) * .5);
  }
}
