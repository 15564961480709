.o-support {
  & &__title-list &__title-list__item {
    display: inline-block;
    margin-top: .5em;
    font-size: 1.1rem;
    cursor: pointer;

    &:first-child {
      margin-top: 0;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
