.c-nav {
  & &__item {
    overflow: hidden;
    width: 11em;
    padding: .2em;
    margin-left: auto;
    transform: translateX(-30%);
    font: 400 1.7rem Rubik, serif;
    opacity: 0;
    transition: transform .3s,
    opacity .15s;
    @media (--phone){
      padding: 0;
      margin-top: .4em;
      font-size: 1.7rem;
    }
  }
}

@for $i from 1 to 6 {
  .c-menu-nav[data-status="true"] .c-nav__item:nth-child($(i)) {
    --index: $i;
  }
}

.c-menu-nav[data-status="true"] .c-nav__item {
  opacity: 1;
  transform: translateX(0);
  transition-delay: calc(var(--second) + .08s * var(--index)), 
  calc(var(--second) + .08s * var(--index));
}
