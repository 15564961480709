.o-who {
  & &__content {
    padding: 1em 0 3em;
    opacity: 0;
    transform: translateX(-4em);
    transition: calc( var(--second) * 1.5);
    @media (--desktop) {
      padding: 3em 10% 1em 40%;
    }
    @media (--phone) {
      padding: 1em 0 2em;
    }
  }

  & .swiper-slide-active &__content {
    transform: translateX(0);
    opacity: 1;
  }
}
