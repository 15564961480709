.an-move {
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--black);
    transition: calc( var(--second) * 2.5 ) calc( var(--second) * .5 );
  }

  &[data-active="true"]&--up {
    &::after {
      transform: translateY(-110%);
    }
  }
}
