.c-logo {
  position: fixed;
  top: 6%;
  left: 3%;
  z-index: var(--zIndex__top);
  width: 3.4em;
  transition: filter .8s;
  @media (--phone) {
    top: 3%;
  }

  &&.is-invert {
    filter: invert(1);
  }
}
