.c-navigation {
  visibility: hidden;
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 9;
  width: 12em;
  text-align: right;
  transform: translateY(-50%);
  @media (--desktop) {
    visibility: visible;
  }
}
