.o-support {
  & &__item {
    @mixin backgroundMove;
    padding: calc(var(--wh) / 4) 0;

    &::before {
      display: none;
    }
  }

  & &__content[data-status="true"] &__item {
    &::after {
      transform: translateX(110%);
    }
  }
}

@for $i from 1 to 10 {
  .o-support .o-support__item:nth-child($(i))::after {
    transition-delay: .$(i)s;
  }
}
