.u-plane {
  position: fixed;
  right: 2.5%;
  bottom: 5%;
  z-index: 2;
  width: var(--planeWH);
  height: var(--planeWH);
  border: none;
  border-radius: 50%;
  background: svg-inline(plane) center/60% auto no-repeat color( var(--black) l(+10%) );
  box-shadow: 0 0 7px color(var(--black) a(.9));
  cursor: pointer;
  transition: box-shadow .5s, 
  background-color .5s;
  @media (--phone) {
    width: calc(var(--planeWH) * .9);
    height: calc(var(--planeWH) * .9);
  }

  &:hover {
    box-shadow: 0 0 10px color(var(--black) a(.9)),
    4px 4px 6px color(black a(.3));
    background-color: var(--black);
  }
}
