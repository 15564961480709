.c-languages {
  & &__item {
    display: inline-block;

    + .c-languages__item {
      &::before {
        content: '|';
        display: inline-block;
        margin: 0 1em;
        vertical-align: middle;
      }
    }
  }
}
