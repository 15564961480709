.c-menu-nav {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--zIndex__bottom);
  width: 100%;
  min-height: 100vh;
  background-color: white;
  visibility: hidden;
  transition: visibility .05s var(--second);

  &[data-status="true"] {
    visibility: visible;
    transition: visibility .05s;
  }
}
