.c-pop-form {
  & &__close {
    transition: opacity .2s var(--second);
  }

  &[data-status="false"] .c-pop-form__close {
    opacity: 0;
    transition: opacity .2s;
  }
}
