.c-languages {
  & &__font {
    font: 400 1rem/1.2 Rubik, sans-serif;

    &:hover {
      text-decoration: underline;
    }

    &.is-current {
      font-weight: 700;
    }
  }
}
