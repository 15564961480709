.u-section {
  padding: 2.8em 10% 4em;
  @media (--phone) {
    padding: 2.8em 1em 4em;
  }

  &&--reverse {
    padding: 4em 10% 2em;
    @media (--phone) {
      padding: 4em 1em 2em;
    }
  }

  &&--fix {
    padding: 70px 10%;
    @media (--phone) {
      padding: 30px 1em;
    }
  }
}
