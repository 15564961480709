.c-pop-form {
  & &__bg {
    position: absolute;
    bottom: calc(100vw / -1);
    right: calc(100vw / -1);
    width: calc(100vw * 3.5);
    height: calc(100vw * 3.5);
    background-color: var(--black);
    border-radius: 50%;
    transition: calc( var(--second) * 2 ) linear;
  }

  &[data-status="false"] .c-pop-form__bg {
    width: var(--planeWH);
    height: var(--planeWH);
    transition: calc( var(--second) * 2 );
    @media (--desktop) {
      transition: calc( var(--second) * 3 );
    }
    @media (--phone) {
      width: calc(var(--planeWH) * .9);
      height: calc(var(--planeWH) * .9);
    }
  }
}
