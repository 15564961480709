.c-flex {
  display: flex;

  &&--row {
    flex-flow: row wrap;
  }

  &&--column {
    flex-flow: column wrap;
  }
}
