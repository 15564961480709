.c-pop-form {
  & &__wrap {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 990px;
    padding: 0 1em;
    text-align: center;
    transition: opacity .3s var(--second);
    @media (--phone){
      width: 90%;
    }
  }

  &[data-status="false"] .c-pop-form__wrap {
    opacity: 0;
    transition: opacity .2s;
  }
}
