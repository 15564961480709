.o-particle {
  & &__item {
    --p: 45%;
    --wh: 1em;
    @media (--desktop) {
      --p: 30%;
    }
    @media (--phone) {
      --p: 60%;
    }

    position: relative;
    width: 60%;
    max-width: 15em;
    margin-top: 15%;
    font-weight: 700;
    @media (--desktop) {
      width: 30%;
    }
    @media (--ipad) {
      margin-left: calc( 100% - var(--p) );
      margin-top: 20%;
      padding-left: calc(var(--wh) + .5em);
    }
    @media (--ipad_mini) {
      margin-left: calc( 100% - var(--p) );
      margin-top: 20%;
      padding-left: calc(var(--wh) + .5em);
    }
    @media (--phone) {
      width: 50%;
      margin-top: 30%;
      margin-left: calc( 100% - var(--p) );
      padding-left: calc(var(--wh) + .5em);
    }
    
    
    &::before {
      content: '';
      position: absolute;
      top: .1em;
      width: var(--wh);
      height: var(--wh);
      background: url(../images/icon_symbool@3x.png) 50%/contain no-repeat;
      @media (--ipad) {
        left: 0;
      }
      @media (--ipad_mini) {
        left: 0;
      }
      @media (--phone) {
        left: 0;
      }
    }

    &:nth-child(2n)::before {
      transform: rotate(180deg);
    }

    @media (--desktop) {
      &:nth-child(odd) {
        padding-left: calc(var(--wh) + .5em);
        margin-left: calc(100% - var(--p));
        text-align: left;

        &::before {
          left: 0;
        }
      }

      &:nth-child(even) {
        padding-right: calc(var(--wh) + .5em);
        margin-left: auto;
        margin-right: calc(100% - var(--p));
        text-align: right;

        &::before {
          right: 0;
        }
      }
    }
  }
}
