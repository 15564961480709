.c-social {
  &&_themes_circle &__item {
    width: 1.7em;
    height: 1.7em;
    line-height: 1.7em;
    background-color: var(--black);
    border-radius: 1.7em;
    text-align: center;
  }
}
