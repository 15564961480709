.c-form {
  & &__input {
    box-sizing: border-box;
    width: 49%;
    height: 2.5em;
    margin-top: .5em;
    padding: .2em 1em;
    border: none;
    border-radius: .7em;
    font: .7rem/1.6 Rubik, sans-serif;
    color: var(--black);
    @media (--phone) {
      width: 100%;
      font-size: .8rem;
    }

    &::placeholder {
      opacity: 1;
      text-transform: lowercase;
      color: color( var(--gray) b(+40%) );
    }
    &::-ms-placeholder {
      text-transform: lowercase;
      color: color( var(--gray) b(+40%) );
    }
    &:-ms-placeholder {
      text-transform: lowercase;
      color: color( var(--gray) b(+40%) );
    }

    &_message {
      width: 100%;
      height: 10em;
      resize: none;
    }
  }
}
