.c-menu-nav {
  & {
    &__bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: var(--zIndex__bottom);
      background-color: var(--blue);
      transform: translateX(-100%);
      transition: transform var(--second) cubic-bezier(.05, .55, .55, .05);
    }

    &[data-status="true"] {
      .c-menu-nav__bg {
        transform: translateX(100%);
      }
    }
  }
}
