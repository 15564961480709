.o-experience {
  & &__item {
    --wh: calc(100% / 2);
    @media (--desktop) {
      --wh: calc(100% / 3);
    }

    position: relative;
    width: var(--wh);
    padding-top: calc( var(--wh) * 1.3);
    font-size: 1rem;
    filter: grayscale(1) brightness(1) opacity(.6);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color var(--second);
    }

    &:nth-child(odd)::before {
      background-color: var(--ex__blue);
    }

    &:nth-child(even)::before {
      background-color: var(--ex__blue_l);
    }

    &:hover {
      filter: none;

      &::before {
        background-color: var(--ex__blue_d);
      }
    }
  }
}
