.c-list {
  &&_themes_circle &__item {
    --wh: 1em;
    position: relative;
    padding-left: var(--wh); 
    margin-top: 0;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: calc( var(--wh) / 4);
      height: calc( var(--wh) / 4);
      border-radius: var(--wh);
      background-color: currentColor;
    }
  }
}
