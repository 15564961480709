.c-list {
  &&_themes_double &__item {
    --pl: 1em;
    position: relative;
    padding-left: var(--pl);

    &::before {
      content: '\00BB';
      position: absolute;
      top: 0;
      left: 0;
      font-size: inherit;
      color: currentColor;
    }
  }
}
