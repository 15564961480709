@for $i from 1 to 9 {
  .u-mt--$(i) {
    margin-top: $(i)em;
  }

  .u-mt--$(i)\@lg {
    @media (--desktop) {
      margin-top: $(i)em;
    }
  }

  .u-mt--$(i)\@md {
    @media (--ipad) {
      margin-top: $(i)em;
    }
    @media (--ipad_mini) {
      margin-top: $(i)em;
    }
  }

  .u-mt--$(i)\@xs {
    @media (--phone) {
      margin-top: $(i)em;
    }
  }
}
