.c-navigation {
  & &__font {
    position: relative;
    font: 700 12px Rubik, sans-serif;
    color: color(white b(+50%));
    transition: color calc(var(--second) - .4s), padding-right calc( var(--second) - .3s);

    &::before,
    &::after {
      content: '';
      display: inline-block;
      background-color: color(white b(+50%));
      transition: width calc(var(--second) - .4s), background-color calc(var(--second) - .4s);
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 2.4em;
      width: calc( 100% - 10px );
      height: 1px;
    }

    &::after {
      width: 16px;
      height: 3px;
      margin: -2px 0 0 8px;
      vertical-align: middle;
    }

    &:hover {
      padding-right: 12em;
      color: var(--blue);

      &::before,
      &::after {
        background-color: var(--blue);
      }
    }

    &.is-current {
      color: var(--blue);

      &::after,
      &::before {
        background-color: var(--blue);
      }
    }
  }
}
