.o-particle {
  & &__path {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 0 0/contain no-repeat;
    @media (--desktop) {
      background-position: 50% 0;
    }
    @media (--phone) {
      transform: translateX(-20%);
    }
  }
}
@for $i from 1 to 4 {
  .o-particle__path.o-particle__path--$(i) {
    background-image: url(../images/particle_line_$(i).png);
  }
}
