.u-background {
  &--black {
    background-color: var(--black);
  }

  &--blue {
    background-color: var(--blue);
  }

  &--pink {
    background-color: var(--pink);
  }

  &--green {
    background-color: var(--green);
  }
  
  &--gray {
    background-color: var(--gray);
  }
}
