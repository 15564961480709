.c-nav {
  & &__font {
    display: block;
    overflow: hidden;
    position: relative;
    padding: .2em .5em;
    color: var(--black);
    text-transform: lowercase;
    @media (--phone) {
      padding-right: 0;
    }

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      max-height: 0;
      background-color: color(var(--black) l(+60%));
      transition: all .3s cubic-bezier(.6, .2, .4, .8);
    }
  }

  & &__item:hover {
    .c-nav__font:after {
      top: 0;
      max-height: 3em;
    }

    ~ .c-nav__item {
      .c-nav__font:after {
        top: 0;
      }
    }
  }
}
