.c-menu-nav {
  & li&__social {
    transform: scaleX(0);
    transition: transform .2s;

    &:last-child {
      margin-right: 1em;
      @media (--phone) {
        margin-right: 0;
      }
    }
  }

  &[data-status="true"] {
    .c-menu-nav__social {
      transform: scaleX(1);

      &:nth-child(1) {
        transition: transform .3s calc(1.1s + .07s * 1);
      }
      &:nth-child(2) {
        transition: transform .3s calc(1.1s + .07s * 2);
      }
      &:nth-child(3) {
        transition: transform .3s calc(1.1s + .07s * 3);
      }
    }
  }
}
