.o-support {
  & &__part-title {
    position: relative;
    display: inline-block;
    min-width: 10em;
    padding: .1em .6em .1em .1em;
    border-bottom: 2px solid var(--blue);
    cursor: pointer;
    transition: color .3s,
    border-bottom-color .3s;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -.15em;
      border: .2em solid transparent;
      border-top-width: .3em;
      border-top-color: currentColor;
      border-bottom: none;
      vertical-align: middle;
      line-height: 0;
      transition: border-top-color calc( var(--second) / 2),
      transform calc( var(--second) / 2);
    }

    &[data-status="true"] {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
}
