.o-support {
  & &__title-list {
    position: absolute;
    top: 1.4em;
    right: 0;
    left: 0;
    z-index: var(--zIndex__bottom);
    padding: .6em .2em;
    border-radius: 0;
    background-color: white;
  }
}
