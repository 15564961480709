.o-talk {
  & &__select {
    position: relative;
    width: 100%;
    background-color: white;
    border: 1px solid color( var(--gray) b(+40%) );
    color: color( var(--black) l(+50%) );
    font-size: 14px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 1em;
      width: 1em;
      height: 1em;
      border-top: 1px solid var(--gray);
      border-right: 1px solid var(--gray);
    }
  }
}
