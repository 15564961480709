.u-close {
  position: absolute;
  z-index: var(--zIndex__middle);
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  @mixin topRight;

  &:after,
  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: white;
    transition: background-color .2s;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:hover {
    &:before,
    &:after {
      background-color: var(--blue);
    }
  }
}
