.o-support {
  & &__font {
    display: inline-block;
    @mixin backgroundWord;
    @mixin arrow;
    font-size: .9rem;
    transition-delay: calc( var(--second) * .5 + var(--i) * .1s);
  }

  & &__content[data-status="true"] &__font {
    opacity: 1;
  }
}

@for $i from 1 to 10 {
  .o-support .o-support__item:nth-child($(i)) .o-support__font {
    --i:$i;
  }
}
