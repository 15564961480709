.u-title {
  font: 400 2rem/1.4 "Rubik", sans-serif;
  @media (--phone) {
    &\@xs {
      font-size: 1.5rem;
    }
  }

  &&--bold {
    font-weight: 700;
    font-family: "Montserrat";
  }
}
