.c-menu {
  position: fixed;
  z-index: var(--zIndex__middle);
  width: 30px;
  height: 30px;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  @mixin topRight;
}
