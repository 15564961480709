.o-expertise {
  & &__title {
    --wh: .6em;
    position: relative;
    padding-right: calc( var(--wh) * 2);
    @media (--phone) {
      --wh: 10px;
      font-size: 1.3rem;
    }

    &::after,
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 0;
      transition: opacity var(--second) calc( var(--second) / 2);
    }

    &::before {
      width: var(--wh);
      height: var(--wh);
      border-right: 2px solid currentColor;
      border-bottom: 2px solid currentColor;
      transform: translateY(-60%) rotate(45deg);
    }
    
    &::after {
      width: calc( var(--wh) * 2);
      height: calc( var(--wh) * 2);
      mask: url(../images/icon_close@3x.png) 50%/contain no-repeat;
      background-color: currentColor;
      transform: translateY(-50%);
    }
  }

  &[data-status="false"] {
    .o-expertise__title {
      &::after {
        opacity: 0;
        transition: opacity calc( var(--second) / 2);
      }
    }
  }

  &[data-status="true"] {
    .o-expertise__title {
      &::before {
        opacity: 0;
        transition: opacity calc( var(--second) / 2);
      }
    }
  }
}
