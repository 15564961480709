.o-who {
  & &__description {
    font-size: 1.5rem;
    @media (--phone) {
      margin-top: .5em;
      font-size: 1.2rem;
    }

    span {
      &:nth-child(1),
      &:nth-child(2) {
        color: var(--blue);
      }
    }
  }
}
