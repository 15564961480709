.u-text {
  &&_size_b {
    font-size: 1.6rem;
    @media (--phone) {
      &\@xs {
        font-size: 1.4rem;
      }
    }
  }
}
