.an-fade {
  opacity: 0;
  transition: calc( var(--second) * 1.4 );

  &&--up {
    transform: translateY(3em);
  }

  &&--left {
    transform: translateX(-3em);
  }

  &[data-active="true"] {
    opacity: 1;
  }

  &[data-active="true"]&--up {
    transform: translateY(0);
  }

  &[data-active="true"]&--left {
    transform: translateX(0);
  }
}
