.o-experience {
  & &__title {
    position: absolute;
    right: 2em;
    bottom: 20%;
    left: 2em;
    margin-top: 1em;
    opacity: 0;
    font-size: 1.7em;
    font-weight: 700;
    line-height: 1.2;
    @media (--ipad) {
      font-size: 1.3em;
    }
    @media (--ipad_mini) {
      font-size: 1.3em;
    }
    @media (--phone) {
      left: 1em;
      font-size: 1.3em;
    }

    &--fintech {
      bottom: calc( 50% - 1em);
      text-align: right;
    }
    
    &--ecommerce {
      left: 1.2em;
      bottom: 40%;
    }

    &--healthcare {
      bottom: 28%;
    }
  }

  & &__item:hover &__title {
    opacity: 1;
    transition: opacity var(--second);
  }
}
