.o-who {
  & &__button {
    top: auto;
    bottom: 0;
    width: 2em;
    height: 1em;
    margin-top: -.5em;

    &--prev {
      left: 0;
      background: url(../images/icon_arrow_left.png) 50%/contain no-repeat;
    }

    &--next {
      left: 6em;
      right: auto;
      background: url(../images/icon_arrow_right.png) 50%/contain no-repeat;
    }
  }
}
