.an-word {
  & &__content {
    overflow: hidden;
    display: inline-block;
    height: 1.3em;
    line-height: 4;
    transition: line-height calc( var(--second) * 1.5);
  }

  &[data-active="true"] &__content {
    line-height: 1.15;
  }

  &&--delay &__content {
    transition-delay: calc( var(--second) * 1.5 );
  }
}
