.o-talk {
  & &__btn {
    margin: 2em auto 0;
    padding: .3em 2em;
    border-radius: .7em;
    background-color: var(--black);
    font-size: 14px;
    transition: color calc(var( --second ) / 2), background-color calc(var( --second ) / 2);

    &:after {
      display: none;
    }

    &:hover {
      background-color: var(--blue);
    }
  }
}
