.c-form {
  & &__select {
    appearance: none;
    width: 49%;
    height: 2.5em;
    margin-top: .5em;
    padding: 0 0 0 1em;
    background-color: white;
    border-radius: .7em;
    border: none;
    font: .7rem/1.6 Rubik, sans-serif;
    @media (--phone) {
      width: 100%;
      font-size: .8rem;
    }

    &[disabled] {
      background-color: var(--gray);
    }
  }
}
