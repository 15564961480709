.c-menu-nav {
  & {
    & &__wrap {
      padding: 10% 4.8em 2em 2em;
      background-color: white;
      opacity: 0;
      transition: opacity .1s calc( var(--second) / 2 );
      @media (--ipad) {
        padding: 30% 4.8em 2em 2em;
      }
      @media (--ipad_mini) {
        padding: 30% 4.8em 2em 2em;
      }
      @media (--phone) {
        padding: 30% 4.2em 2em;
      }
    }

    &[data-status="true"] {
      .c-menu-nav__wrap {
        opacity: 1;
      }
    }
  }
}
