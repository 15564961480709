.o-expertise {
  & &__content {
    overflow: hidden;
    max-height: 30em;
    transition: max-height var(--second);
    @media (--phone) {
      font-size: .9rem;
    }
  }

  &[data-status="false"] &__content {
    max-height: 0;
  }
}
