.u-device {
  &\@md {
    display: block;
    @media (--desktop) {
      display: none;
    }
  }

  &\@lg {
    display: none;
    @media (--desktop) {
      display: block;
    }
  }
}
