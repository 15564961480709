.c-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--zIndex__top);
  background-color: white;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--blue);
    transform: translateX(-110%);
    animation: loading var(--second) forwards cubic-bezier(.05, .55, .55, .05);
  }
}

@keyframes loading {
  100% {
    transform: translateX(110%);
  }
}
